<template>
<div>
  <Header/>
  <b-container style="max-width:95%; padding-top:70px;">
    <b-overlay :show="showLoader" rounded="sm">
      <b-row>
        <b-col md="6" sm="12">
          <b-row class="mt-2">
            <b-col md="2" sm="3">
              住所：
            </b-col>
            <b-col  md="9" sm="9">
              <b-form-input
                type="text" 
                id="address"
                v-model="$v.facility.address.$model"
                @blur="$v.facility.address.$touch()"
                @change="getLatLng"
              />
              <span v-if="$v.facility.address.$error">
                <small class="text-danger" v-if="!$v.facility.address.required">
                  住所を入力してください
                </small>
              </span>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="2" sm="3">
              緯度：
            </b-col>
            <b-col  md="9" sm="9">
              <b-form-input
                type="text" 
                id="lat"
                v-model="$v.facility.lat.$model"
                @blur="$v.facility.lat.$touch()"
              />
              <span v-if="$v.facility.lat.$error">
                <small class="text-danger" v-if="!$v.facility.lat.required">
                  緯度を入力してください
                </small>
                <small 
                  class="text-danger" 
                  v-if="!$v.facility.lat.validInteger"
                >
                  半角数字、「.」「-」のみ入力可能です。
                </small>
              </span>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="2" sm="3">
              経度：
            </b-col>
            <b-col md="9" sm="9" >
              <b-form-input 
                type="text" 
                id="lng"
                v-model="$v.facility.lng.$model"
                @blur="$v.facility.lng.$touch()"
              />
              <span v-if="$v.facility.lng.$error">
                <small class="text-danger" v-if="!$v.facility.lng.required">
                  経度を入力してください
                </small>
                <small 
                  class="text-danger" 
                  v-if="!$v.facility.lng.validInteger"
                >
                  半角数字、「.」「-」のみ入力可能です。
                </small>
              </span>
            </b-col>
          </b-row>
          <b-row >
            <b-col class="text-center">
              <b-button 
                variant="success"
                style="width:50%;"
                @click="searchLatLng(facility.lat,facility.lng)"
              >緯度経度検索</b-button>
            </b-col>
          </b-row>

          <dl class="row mt-2">
            <b-col sm="3" md="2">
              <dd>施設区分：</dd>
            </b-col>
            <b-col sm="9" md="9">
              <dd>
                <div 
                  v-for="facilityType in allFacilityTypes"
                  :key="facilityType.inClassificationID"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    name="tags"
                    v-model="facility.types"
                    class="form-check-input" 
                    type="checkbox"  
                    :id="facilityType.inClassificationID"
                    :value="facilityType.inClassificationID"
                  >
                  <label
                    v-if="facilityType.vcMiddleClassName === '' || facilityType.vcMiddleClassName === null "
                    class="form-check-label" 
                    :for="facilityType.inClassificationID"
                  >
                    {{ facilityType.vcClassificationName }}
                  </label>  
                  <label
                    v-else
                    class="form-check-label" 
                    :for="facilityType.inClassificationID"
                  >
                    {{ facilityType.vcMiddleClassName }}
                  </label>
                </div>
              </dd>
            </b-col>
          </dl>

          <b-row class="mt-2">
            <b-col md="2" sm="3">
              絞込み距離：
            </b-col >
            <b-col md="9" sm="9">
              <select 
                class="form-select" 
                name="distance" 
                v-model="facility.distance"
              >
                <option value="">選択してください</option>
                <option 
                  v-for="distance in distances" 
                  :key="distance.id"
                  :value="distance.value"
                >
                  {{ distance.text }}
                </option>
              </select>
            </b-col> 
          </b-row>
          <b-row class="mt-1">
            <b-col class="text-center">
              <b-button
                variant="primary"
                style="width:50%" 
                @click="getAutoDistanceList('distance','asc')"
              >
                検索
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row class="mt-4">
            <b-col md="2" sm="3">
              施設名検索：
            </b-col>
            <b-col md="7" sm="9">
              <b-form-input 
                type="text" 
                id="searchName"
                v-model="searchName"
                placeholder="施設名で検索を行うときのみ使用"
              />
            </b-col>
            <b-col md="2">
              <b-button
                variant="success"
                @click="searchFacilityName()"
              >
                検索
              </b-button>
            </b-col>
            <span v-if="message.searchName !== ''">
              <b class="text-danger">
                {{ message.searchName }}
              </b>
            </span>
          </b-row>

          <div 
            class="table-responsive mt-3" 
            v-if="distanceList.length !== 0"
          >
            <b-row>
              <b-col></b-col>
            </b-row>
            <table class="table table-hover table_sticky">
              <thead class="table-secondary">
                <tr>
                  <th scope="col" 
                    style="width:5%"
                  ></th>
                  <th 
                    scope="col" 
                    style="width:15%"
                  >
                    施設区分
                    <button 
                      type="button" 
                      :class="sort.inClassificationIDAsc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
                    >
                      <b-icon 
                        :icon="upIcon"
                        @click="sortList('t4.inClassificationID','asc')"
                      ></b-icon>
                    </button>
                    <button 
                      type="button" 
                      :class="sort.inClassificationIDDesc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
                    >
                      <b-icon 
                        :icon="downIcon"
                        @click="sortList('t4.inClassificationID','desc')"
                      ></b-icon>
                    </button>
                  </th>
                  <th 
                    scope="col" 
                    style="width:15%"
                  >
                    施設名<br>詳細名
                  </th>
                  <th 
                    scope="col" 
                    style="width:15%"
                  >
                    直線距離
                    <button 
                      type="button" 
                      :class="sort.distanceAsc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
                    >
                      <b-icon 
                        :icon="upIcon"
                        @click="sortList('distance','asc')"
                      ></b-icon>
                    </button>
                    <button 
                      type="button" 
                      :class="sort.distanceDesc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
                    >
                      <b-icon 
                        :icon="downIcon"
                        @click="sortList('distance','desc')"
                      ></b-icon>
                    </button>
                  </th>
                  <th
                    scope="col" 
                    style="width:15%"
                  >
                    備考
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr 
                  v-for="(list, index) in distanceList" 
                  :key="'distance' + index"
                  style="cursor:pointer;" 
                  @click="getRoute(facility.lat,facility.lng,list.doLatitude,list.doLongitude,
                    list.vcFacilityName,list.inFacilityDetailsID,list.inFacilityID,list.vcCorrectionVal )"
                  :class="{'table-active': list.activeFlag}"
                >
                  <th scope="row">
                    {{ index + 1 }}
                  </th>
                  <td v-if="list.vcMiddleClassName === '' || list.vcMiddleClassName === null">
                    {{ list.vcClassificationName }}
                  </td>
                  <td v-else>
                    {{ list.vcMiddleClassName }}
                  </td>
                  <td>
                    {{ list.vcFacilityName }}<br>
                    {{ list.vcFacilityDetailName }}
                  </td>
                  <td>
                    {{ list.distance }}
                  </td>
                  <td>
                    {{ list.txNote }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else-if="message.error !== ''" 
            class="mt-4"
          >
            <b class="text-danger">
              {{ message.error }}
            </b>
          </div>
          <div 
            v-else-if="message.distance !== ''" 
            class="mt-4"
          >
            <b class="text-danger">
              {{ message.distance }}
            </b>
          </div>
          <div 
            class="table-responsive mt-3" 
            v-if="searchLists.length !== 0"
          >
            <b-row>
              <b-col></b-col>
            </b-row>
            <table class="table table-hover table_sticky">
              <thead class="table-secondary">
                <tr>
                  <th scope="col" 
                    style="width:5%"
                  ></th>
                  <th 
                    scope="col" 
                    style="width:10%"
                  >
                    施設区分
                  </th>
                  <th 
                    scope="col" 
                    style="width:15%"
                  >
                    施設名<br>詳細名
                  </th>
                  <th 
                    scope="col" 
                    style="width:15%"
                  >
                    住所
                  </th>
                  <th 
                    scope="col" 
                    style="width:15%"
                  >
                    備考
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr 
                  v-for="(searchList, index) in searchLists"
                  :key="'searchList' + index"
                  style="cursor:pointer;" 
                  @click="getRoute(facility.lat,facility.lng,searchList.doLatitude,searchList.doLongitude,
                    searchList.vcFacilityName,searchList.inFacilityDetailsID,searchList.inFacilityID,searchList.vcCorrectionVal  )"
                  :class="{'table-active': searchList.activeFlag}"
                >
                  <th scope="row">
                    {{ index + 1 }}
                  </th>
                  <td v-if="searchList.vcMiddleClassName === '' || searchList.vcMiddleClassName === null">
                    {{ searchList.vcClassificationName }}
                  </td>
                  <td v-else>
                    {{ searchList.vcMiddleClassName }}
                  </td>
                  <td>
                    {{ searchList.vcFacilityName }}<br>
                    {{ searchList.vcFacilityDetailName }}
                  </td>
                  <td>
                    {{ searchList.vcFacilityAdd}}
                  </td>
                  <td>
                    {{ searchList.txNote }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col md="6" sm="12">
          <b-row>
            <b-col md="3">
              施設名：
            </b-col>
            <b-col md="9">
              <b-form-input
                v-model="selectFacility.name"
                readonly
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              時間（徒歩）：
            </b-col>
            <b-col md="9">
              <b-form-input
                v-model="selectFacility.time"
                readonly
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              実測距離：
            </b-col>
            <b-col md="9">
              <b-form-input
                v-model="selectFacility.distance "
                readonly
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              補正値：
            </b-col>
            <b-col md="9">
              <b-form-input
                v-model="$v.selectFacility.correctionVal.$model "
                @change="getCorDistance()"
                @blur="$v.selectFacility.correctionVal.$touch()"
              ></b-form-input>
              <span v-if="$v.selectFacility.correctionVal.$error">
                <small 
                  class="text-danger" 
                  v-if="!$v.selectFacility.correctionVal.validInteger"
                >
                  半角数字のみ入力可能です。
                </small>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              補正距離：
            </b-col>
            <b-col md="9">
              <b-form-input
                v-model="selectFacility.corDistance"
                readonly
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-center">
              <b-button
                variant="primary"
                style="width:50%;"
                @click="putLatLngModal()"
                :disabled="!changeLatLngFlag"
              >
                緯度・経度・補正値更新
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col>
              <b-button 
                variant="success"
                @click="addPoint"
                :disabled="routeLine.length === 0 || maxViaFlag"
              >
                経由地を追加する
              </b-button>
            </b-col>
            <b-col>
              <b-button 
                variant="danger"
                @click="deleteViaPoint()"
                :disabled="!viaRouteFlag || distanceViaPin.length === 0"
              >
                経由地を削除する
              </b-button>
            </b-col>
            <b-col>
              <b-button
                variant="outline-primary"
                @click=" getAddViaRouteDistance(
                  facility.lat,facility.lng,selectFacility.lat,
                  selectFacility.lng,selectFacility.name,
                  viaPoint,selectFacility.correctionVal)"
                :disabled="!viaRouteFlag"
              >
                ルート更新
              </b-button>
            </b-col>
          </b-row>
          <div 
            id="map" 
            class="mx-auto mt-3" 
            style="height:400px; width:100%;"
          ></div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import { Apimixin } from '../../mixins/api'
import { Maploader } from '../../mixins/mapload'
import { helpers, required } from "vuelidate/lib/validators"
const validInteger = helpers.regex('validInteger', /^[0-9.-]+$/)

export default {
  title: '自動距離測',
  components: {
    Header,

  },
  mixins:[Apimixin, Maploader],
  data() {
    return {
      role:'',
      userID:'',
      showLoader:false,
      facility: {
        lat:'',
        lng:'',
        address:'',
        types:[],
        distance:''
      },
      selectFacility: {
        id:'',
        detailID:'',
        name:'',
        lat:'',
        lng:'',
        correctionVal:'',
        distance:'',
        corDistance:'',
        time:''
      },
      viaPoint: [
        {
          id:0,
          lat:'',
          lng:''
        }
      ],
      details:[
        {
          detailActive:false
        }
      ],
      distances:[
        {id:1, value:0.1, text:'100m'},
        {id:2, value:0.5, text:'500m'},
        {id:3, value:1, text:'1km'},
        {id:4, value:5, text:'5km'},
        {id:5, value:10, text:'10km'},
      ],
      upIcon:'chevron-up',
      downIcon:'chevron-down',
      sort:[ 
        {distanceAsc:false},
        {distanceDesc:false},
        {inClassificationIDAsc:false},
        {inClassificationIDDesc:false},
      ],
      distanceList:[],
      message: {
        error:'',
        distance:'',
        searchName:''
      },
      distanceViaPin:[],
      distancePin:'',
      distanceMainPin:'',
      routeLine:[],
      searchName:'',
      searchLists:[],
      changeLatLngFlag:false,
      viaRouteFlag:false,
      viaID:0,
      maxViaFlag:false,
    }
  },
  created() {
    //デフォルトのピン位置設定
    this.facility.lat =  35.67227138888889
    this.facility.lng =  139.80327305555556
    //施設区分情報取得
    this.getFacilityType('automaticDistance');
  },
  mounted() {
    this.role = sessionStorage.getItem('role')
    this.userID = sessionStorage.getItem('userId')
    //マップ描画
    this.mapInit(this.facility.lat, this.facility.lng);
    //権限がない場合マイページにプッシュ
    if(!this.autoDistanceUser ) {
      this.$router.push('/mypage')
    }
  },
  methods:{
    //施設一覧並び替え
    sortList(column,sort) {
      if(column === 't4.inClassificationID' && sort === 'asc') {
        this.sort.inClassificationIDAsc = true
        this.sort.inClassificationIDDesc = false
        this.sort. distanceAsc = false
        this.sort. distanceDesc = false
      } else if(column === 't4.inClassificationID' && sort === 'desc') {
        this.sort.inClassificationIDAsc = false
        this.sort.inClassificationIDDesc = true
        this.sort. distanceAsc = false
        this.sort. distanceDesc = false
      } else if(column === 'distance' && sort === 'asc') {
        this.sort.inClassificationIDAsc = false
        this.sort.inClassificationIDDesc = false
        this.sort. distanceAsc = true
        this.sort. distanceDesc = false
      } else if(column === 'distance' && sort === 'desc'){
        this.sort.inClassificationIDAsc = false
        this.sort.inClassificationIDDesc = false
        this.sort. distanceAsc = false
        this.sort. distanceDesc = true
      }
      this.getAutoDistanceList(column,sort)
    },
  
    //補正値の変更
    getCorDistance() {
      let distance = this.selectFacility.distance.slice(0,-1)
      this.selectFacility.corDistance = Math.ceil((Number(distance) + Number(this.selectFacility.correctionVal)) * 0.1) / 0.1
      let corDistance = ((Number(distance) + Number(this.selectFacility.correctionVal)) * 0.1) / 0.1
      this.selectFacility.time = Math.ceil(corDistance / 80) + '分'
      this.selectFacility.corDistance = this.selectFacility.corDistance + 'm'
    },

    //施設緯度経度の更新
    putLatLngModal() {
      if(this.$v.selectFacility.correctionVal.validInteger) {
        this.$swal({
          title: '施設情報更新',
          html: `「${ this.selectFacility.name }」の緯度・経度・補正値を更新しますか？`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '確定',
          cancelButtonText:'キャンセル',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: '処理中',
              html: '処理終了まで画面はそのままにしてください。', 
              allowOutsideClick : false , 
              showConfirmButton: false, 
              onBeforeOpen: () => {
                this.$swal.showLoading()
              }
            }); 
            this.putLatLng(
              this.selectFacility.id,this.selectFacility.detailID,
              this.selectFacility.lat,this.selectFacility.lng,this.selectFacility.correctionVal)
          }
        })
      } else {
        this.$swal({
          icon: 'error',
          html:'補正値は半角数字で入力してください。',
          confirmButtonText:"閉じる"
        })
      }
    }
  },
  watch:{
    'selectFacility.distance':function() {
      this.getCorDistance()
    }
  },

  validations: {
      facility: {
        address: {
          required
        },
        lat: {
          validInteger,
          required
        },
        lng: {
          validInteger,
          required
        },
      },
      selectFacility: {
        correctionVal:{
          validInteger
        }
      }
    }
}
</script>
<style scoped>
.table_sticky {
  display: block;
  overflow-y: scroll;
  height: calc(35vh);
    
}
.table_sticky thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.customizable {
  z-index: 1600 !important;
}
.btn_class {
  background-color: white !important;
}
</style>

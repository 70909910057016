
export const Maploader = {
  data(){
    return {
      map:'',
      mappin:'',
      mappins:[],
      mainMapPin:'/static/images/main_pin.png',
      detailMapPin:'/static/images/detail_pin.png',
      addMapPin:'/static/images/via_pin.png',
      pinMoveFlag:false,
      updateFacilityFlag:false,
      autoDistance:'/autodistance'
    }
  },
  mounted(){
    let script = document.createElement('script');
    script.src = "https://trial.api-service.navitime.biz/t2000310/v1/map_script?host="+ process.env.VUE_APP_HOST +"&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750";
    script.async = true;
    document.head.appendChild(script);
  },
  methods:{
    //マップ描画
    mapInit(lat,lng) {
      let zoom = ''
      if(this.$route.path === this.autoDistance) {
        zoom = 15
      } else {
        zoom = 18
      }

      let timer = setInterval(() => {
        if(window.navitime){
          clearInterval(timer);
          let latlng = new window.navitime.geo.LatLng(lat, lng);
          this.map = new window.navitime.geo.Map(
            'map',
            latlng,
            zoom,
            {
              dblclickZoom:false,
              disableZoomBar:false
            }
          );
          this.getAddress(lat, lng);
          this.createMappin(latlng,this.mainMapPin)
          if(this.details[0].detailActive && this.details[0].pin) {
            for(let i = 0;i < this.details.length;i ++) {
              this.createDetailmappin(this.details[i].detailName,this.details[i].lat,this.details[i].lng)
            }
          }
          //地図をダブルクリック時地図の中心にピンを移動
          if(this.$route.path !== this.autoDistance) {
            const CLICK_EVENT = ('ontouchend' in window) ?
              'touchend' : 'dblclick';
            window.navitime.geo.util.addListener(
              this.map,
              CLICK_EVENT,
              this.moveCenter
            );
          }
          var menu = new window.navitime.geo.control.MenuControl({
            map: this.map
          });
          let self = this
          menu.addMenuColumn("この地点", function(){
            let lat =(menu.getLatLng().lat / 3600000)
            let lng =(menu.getLatLng().lng  / 3600000)
            let latlng = new window.navitime.geo.LatLng(lat,lng);
            let pin = new window.navitime.geo.overlay.Pin({
              icon: self.detailMapPin,
              position:latlng,
              draggable:true,
              cursor:'pointer'
            })
            console.log(pin)
            pin.setMap(self.map);
          // self.map.panTo(menu.getLatLng())
          // let latlng = new window.navitime.geo.LatLng(self.facility.lat,self.facility.lng);
          // self.mappin.setPosition(latlng)
          // self.changeLatLng(self.facility.lat,self.facility.lng )
          });
        }
      },500)
    },
    //住所取得
    getAddress(lat,lng) {
      this.axios.get(`/mapapi/t2000310/v1/address/reverse_geocoding?coord=${lat},${lng}&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750`)
      .then(response => {
        console.log(response)
        this.facility.address = response.data.items[0].name
        // this.showLoader = false
      })
      .catch(error => {
        console.log(error)
        // this.showLoader = false
      })
    },

    //地図の中心の緯度経度取得
    moveCenter() {
      this.facility.lat = (this.map.getCenter().lat / 3600000)
      this.facility.lng = (this.map.getCenter().lng / 3600000)
      let latlng = new window.navitime.geo.LatLng(this.facility.lat,this.facility.lng);
      this.mappin.setPosition(latlng)
      this.changeLatLng(this.facility.lat,this.facility.lng)
    },

    //マップピン描画
    createMappin(latlng,mappin) {
      if(this.$route.path === this.autoDistance) {
        if(this.routeLine.length !== 0) {
          this.mappin.setVisible(false)
          this.mappin.setMap(null)
        }
      }
      this.mappin = new window.navitime.geo.overlay.Pin({
        icon: mappin,
        position:latlng,
        draggable:true,
        title:"代表情報",
        cursor:'pointer',
      })
        window.navitime.geo.util.addListener(
          this.mappin,
          'dragend',
          this.getMappinPosition
        );
      this.mappin.setMap(this.map);
    },

    //マップ位置移動
    changeLatLng(lat,lng) {
      this.pinMoveFlag=true
      let zoom = this.map.getZoom()
      this.map.moveTo(new window.navitime.geo.LatLng(lat,lng),zoom);
      let latlng = new window.navitime.geo.LatLng(lat,lng);
      if(this.$route.path === this.autoDistance ) {
        if(this.routeLine.length !== 0) {
          this.createMappin(latlng,this.mainMapPin)
        } else {
          this.mappin.setPosition(latlng)
        }
      } else {
        this.mappin.setPosition(latlng)
      }
      this.getAddress(lat,lng);
    },
    //マップピンの位置の緯度経度取得
    getMappinPosition() {
      let position = this.mappin.getPosition()
      this.facility.lat =  (position.lat / 3600000)
      this.facility.lng =  (position.lng / 3600000)
      this.changeLatLng(this.facility.lat,this.facility.lng)
      if(this.$route.path === this.autoDistance ) {
        if(this.routeLine.length !== 0) {
          if(this.viaPoint[0].lat === '' && this.viaPoint[0].lng === '') {
            this.getRouteDistance(
              this.facility.lat,this.facility.lng,this.selectFacility.lat,
              this.selectFacility.lng,this.selectFacility.name,this.selectFacility.detailID,
              this.selectFacility.id,this.selectFacility.correctionVal)
          } else {
            this.getAddViaRouteDistance(
              this.facility.lat,this.facility.lng,this.selectFacility.lat,
              this.selectFacility.lng,this.selectFacility.name,this.viaPoint,this.selectFacility.correctionVal)
          }
        }
      }
    },

    //付帯情報のマップピンの位置の緯度経度取得
    getDetailsMappinPosition() {
      for(let p = 0;p < this.mappins.length; p++) {
        if(this.mappins[p]._options.add === null) {
          for(let i = 0;i < this.details.length;i++) {
            for(let t = 0;t < this.mappins.length;t++) {
              if(this.details[i].id === this.mappins[t]._options.id) {
                let position = this.mappins[t].getPosition()
                this.details[i].lat =  (position.lat / 3600000)
                this.details[i].lng =  (position.lng / 3600000)
              }
            }
          }
        } else {
          for(let i = 0;i < this.addDetails.length;i++) {
            for(let t = 0;t < this.mappins.length;t++) {
              if(this.addDetails[i].id === this.mappins[t]._options.id) {
                let position = this.mappins[t].getPosition()
                this.addDetails[i].lat =  (position.lat / 3600000)
                this.addDetails[i].lng =  (position.lng / 3600000)
              }
            }
          }
        }
      }
    },

    //付帯情報マップピン描画
    createDetailmappin(name,id,lat,lng,add = null) {
      let mappin = ""
      if(add === null) {
        mappin = this.detailMapPin
      } else {
        mappin = this.addMapPin
      }
      let latlng = new window.navitime.geo.LatLng(lat, lng);
      let pin = new window.navitime.geo.overlay.Pin({
        icon: mappin,
        position:latlng,
        draggable:true,
        title:name,
        cursor:'pointer',
        id:id,
        add: add
      })
      this.mappins.push(pin)
      for(let i = 0; i< this.mappins.length; i++) {
        window.navitime.geo.util.addListener(
          this.mappins[i],
          'dragend',
          this.getDetailsMappinPosition
        );
        this.mappins[i].setMap(this.map);
      }

    },
    //住所より緯度経度取得
    getLatLng(address) {
      if(this.$route.path !== this.autoDistance) {
        this.axios.get(`/mapapi/t2000310/v1/address?word=${address}&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750`)
        .then(response => {
          this.facility.lat = response.data.items[0].coord.lat
          this.facility.lng = response.data.items[0].coord.lon
          this.changeLatLng(this.facility.lat,this.facility.lng)
        })
        .catch(error => {
          console.log(error.response)
        })
      } else {
        this.axios.get(`/mapapi/t2000310/v1/address?word=${address}&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750`)
        .then(response => {
          this.facility.lat = response.data.items[0].coord.lat
          this.facility.lng = response.data.items[0].coord.lon
          this.selectFacility.lat = ''
          this.selectFacility.lng = ''
          this.deleteRouteLine(
            this.facility.lat,this.facility.lng,this.selectFacility.lat,
            this.selectFacility.lng,this.selectFacility.name,this.selectFacility.detailID,
            this.selectFacility.id,this.selectFacility.correctionVal)
          this.changeLatLng(this.facility.lat,this.facility.lng)
        })
        .catch(error => {
          console.log(error.response)
        })
      }
    },

    searchLatLng(lat,lng) {
      // this.showLoader = true
      for(let i = 0;i < this.distanceViaPin.length;i++) {
        this.distanceViaPin[i].setVisible(false)
      }
      for(let t = 0;t < this.distanceViaPin.length; t++) {
        this.viaPoint.splice(1,t)
      }
      this.distancePin.setVisible(false)
      this.viaPoint[0].lat = ''
      this.viaPoint[0].lng = ''
      this.viaID = 0
      this.maxViaFlag = false
      this.distanceViaPin = []
      for(let i = 0;i < this.routeLine.length;i++) {
        this.routeLine[i].setVisible(false)
      }
      this.routeLine = []

      this.changeLatLng(lat,lng)
    },

    //↓----自動距離測-----

    //描画されている二地点の経路を削除
    deleteRouteLine(facilityLat,facilityLng,selectLat,selectLng,selectName,selectID,mainID,selectCor) {
      for(let i = 0;i < this.routeLine.length;i++) {
        this.routeLine[i].setVisible(false)
      }
      this.routeLine = []
      this.getRouteDistance(facilityLat,facilityLng,selectLat,selectLng,selectName,selectID,mainID,selectCor)
    },

    //リストから選択した施設のマップピン生成
    createSelectPoint(lat,lng,name) {
      this.selectFacility.lat = ''
      this.selectFacility.lng = ''
      if(this.distancePin !== '') {
        this.distancePin.setVisible(false)
        this.distancePin.setMap(null)
      }
      let latlng = new window.navitime.geo.LatLng(lat, lng);
      this.distancePin = new window.navitime.geo.overlay.Pin({
        icon: this.detailMapPin,
        position:latlng,
        draggable:true,
        title:name,
        cursor:'pointer'
      })
      window.navitime.geo.util.addListener(
        this.distancePin,
        'dragend',
        this.getDistanceMappinPosition
      );
      this.distancePin.setMap(this.map);
      this.selectFacility.name = name
      this.selectFacility.lat = lat
      this.selectFacility.lng = lng
    },

    //経由地ピンを削除
    deleteViaPoint() {
      let last = this.distanceViaPin.length - 1
      this.distanceViaPin[last].setVisible(false)
      if(last === 0) {
        this.viaPoint[0].lat = ''
        this.viaPoint[0].lng = ''
        this.distanceViaPin = []
        this.viaID = 0
      } else {
        this.viaPoint.splice(-1,1)
        this.distanceViaPin.splice(last,1)
      }
      this.maxViaFlag = false
    },

    //経由地追加
    addPoint() {
      if(this.viaPoint[0].lat !== '' && this.viaPoint[0].lng !== '') {
        this.viaID = this.viaPoint.length
        const additionalForm = {
          id:this.viaID,
          lat: "",
          lng: "",
        }
        this.viaPoint.push(additionalForm)
        this.createViaPointPin(this.viaID)
      } else {
        this.createViaPointPin(this.viaID)
      }
    },

    //経由地のピン追加
    createViaPointPin(viaID) {
      this.viaRouteFlag = true
      for(let i = 0;i < this.viaPoint.length; i++) {
        if(viaID === this.viaPoint[i].id) {
          this.viaPoint[i].lat = (this.map.getCenter().lat / 3600000)
          this.viaPoint[i].lng = (this.map.getCenter().lng / 3600000)
          let latlng = new window.navitime.geo.LatLng(this.viaPoint[i].lat,this.viaPoint[i].lng);
          let pin = new window.navitime.geo.overlay.Pin({
            icon: this.addMapPin,
            position:latlng,
            title:'経由地' + (this.viaID + 1),
            draggable:true,
            cursor:'pointer',
            id:viaID
          })
          this.distanceViaPin.push(pin)
          }
        }
      for(let t = 0; t < this.distanceViaPin.length; t ++) {
        window.navitime.geo.util.addListener(
          this.distanceViaPin[t],
          'dragend',
          this.getDistanceViaMappinPosition
        );
          this.distanceViaPin[t].setMap(this.map)
        if(t === 9) {
          this.maxViaFlag = true
        }
      }
    },

    //経由地マップピン移動後の処理
    getDistanceViaMappinPosition() {
      for(let i = 0; i < this.viaPoint.length; i++) {
        for(let t = 0;t < this.distanceViaPin.length; t++) {
          if(this.viaPoint[i].id === this.distanceViaPin[t]._options.id) {
            let position = this.distanceViaPin[t].getPosition()
            this.viaPoint[i].lat =  (position.lat / 3600000)
            this.viaPoint[i].lng =  (position.lng / 3600000)
          }
        }
      }
    },

    //施設のマップピン位置の緯度経度取得
    getDistanceMappinPosition() {
      this.changeLatLngFlag = true
      let position = this.distancePin.getPosition()
      this.selectFacility.lat =  (position.lat / 3600000)
      this.selectFacility.lng =  (position.lng / 3600000)
      if(this.viaPoint[0].lat === '' && this.viaPoint[0].lng === '') {
        this.getRouteDistance(this.facility.lat,this.facility.lng,this.selectFacility.lat,
        this.selectFacility.lng,this.selectFacility.name,this.selectFacility.detailID,
        this.selectFacility.id,this.selectfacility.correctionVal)
      } else {
        this.getAddViaRouteDistance(this.facility.lat,this.facility.lng,this.selectfacility.lat,
        this.selectfacility.lng,this.selectfacility.name,this.viaPoint,this.selectfacility.correctionVal)
      }
    },

    //施設選択時ルート削除
    getRoute(facilityLat,facilityLng,selectLat,selectLng,selectName,selectID,mainID,selectCor) {
      this.showLoader = true
      for(let i = 0;i < this.distanceViaPin.length;i++) {
        this.distanceViaPin[i].setVisible(false)
      }
      for(let t = 0;t < this.distanceViaPin.length; t++) {
        this.viaPoint.splice(1,t)
      }
      this.viaPoint[0].lat = ''
      this.viaPoint[0].lng = ''
      this.viaID = 0
      this.maxViaFlag = false
      this.distanceViaPin = []
      this.getRouteDistance(facilityLat,facilityLng,selectLat,selectLng,selectName,selectID,mainID,selectCor)

    },

    //二地点の距離測を計測
    getRouteDistance(facilityLat,facilityLng,selectLat,selectLng,selectName,selectID,mainID,selectCor) {
      this.showLoader = true
      this.changeLatLngFlag = true
      this.selectfacility.corDistance = ''
      this.selectfacility.detailID = selectID
      this.selectfacility.id = mainID
      this.selectfacility.correctionVal = selectCor
      if(this.distanceList.length !== 0) {
        for(let i = 0; i < this.distanceList.length;i++) {
          if(this.distanceList[i].inFacilityDetailsID === this.selectfacility.detailID) {
            this.distanceList[i].activeFlag = true
          } else {
            this.distanceList[i].activeFlag = false
          }
        }
      } else {
        for(let i = 0; i < this.searchLists.length;i++) {
          if(this.searchLists[i].inFacilityDetailsID === this.selectfacility.detailID) {
            this.searchLists[i].activeFlag = true
          } else {
            this.searchLists[i].activeFlag = false
          }
        }
      }

      if(this.routeLine.length !== 0) {
        this.deleteRouteLine(facilityLat,facilityLng,selectLat,selectLng,selectName,this.selectfacility.detailID,this.selectfacility.id,selectCor)
      } else {
        if(selectLat === '' || selectLng === '') {
          selectLat = facilityLat
          selectLng = facilityLng
        }
        this.axios.get(`/mapapi/t2000310/v1/route_walk?start={"lat":${facilityLat},"lon":${facilityLng}}&goal={"lat":${selectLat},"lon":${selectLng}}&speed=4.8&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750`)
        .then(response => {
          console.log(response)
          this.selectfacility.distance = response.data.items[0].summary.move.distance + 'm'
          this.selectfacility.corDistance = Math.ceil((Number(response.data.items[0].summary.move.distance) + Number(selectCor)) * 0.1) / 0.1
          let corDistance = ((Number(response.data.items[0].summary.move.distance) + Number(selectCor)) * 0.1) / 0.1
          this.selectfacility.time = Math.ceil(corDistance / 80) + '分'
          this.getRouteLine(facilityLat,facilityLng,selectLat,selectLng,selectName)
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

  //経由地を含めた距離測取
    getAddViaRouteDistance(facilityLat,facilityLng,selectLat,selectLng,selectName,viaPoint,selectCor) {
      this.showLoader = true
      if(this.viaPoint[0].lat !== '' && this.viaPoint[0].lng) {
        for(let i = 0;i < this.routeLine.length;i++) {
          this.routeLine[i].setVisible(false)
        }
        this.routeLine = []
        var via = []
        for(let i = 0;i < viaPoint.length; i++ ){
          via.push({ "lat":viaPoint[i].lat, "lon":viaPoint[i].lng })
        }
        const viaLatLng = JSON.stringify(via);

        this.axios.get(`/mapapi/t2000310/v1/route_walk?start={"lat":${facilityLat},"lon":${facilityLng}}&goal={"lat":${selectLat},"lon":${selectLng}}&via=${viaLatLng}&via_type=optimal&speed=4.8&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750`)
        .then(response => {
          // console.log(response)
          this.selectfacility.correctionVal = selectCor
          this.selectfacility.distance = response.data.items[0].summary.move.distance + 'm'
          this.selectfacility.corDistance = Math.ceil((Number(response.data.items[0].summary.move.distance) + Number(selectCor)) * 0.1) / 0.1
          let corDistance = ((Number(response.data.items[0].summary.move.distance) + Number(selectCor)) * 0.1) / 0.1
          this.selectfacility.time = Math.ceil(corDistance / 80) + '分'
          this.getRouteLine(facilityLat,facilityLng,selectLat,selectLng,selectName,viaLatLng)
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        this.getRouteDistance(
          facilityLat,facilityLng,selectLat,selectLng,selectName,
          this.selectfacility.detailID,this.selectfacility.id,this.selectfacility.correctionVal)
      }
    },

    //二地点の経路情報を取得、マップに描画
    getRouteLine(facilityLat,facilityLng,selectLat,selectLng,selectName,viaLatLng) {
      if(typeof viaLatLng === 'undefined') {
        this.axios.get(`/mapapi/t2000310/v1/shape_walk?start={"lat":${facilityLat},"lon":${facilityLng}}&goal={"lat":${selectLat},"lon":${selectLng}}&format=geojson&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750`)
        .then(response => {
          // console.log(response)
          this.routeLine = window.navitime.geo.GeoJSON.draw({map: this.map, json: response.data});
          this.createSelectPoint(selectLat,selectLng,selectName)
          let latlng = new window.navitime.geo.LatLng(this.facility.lat,this.facility.lng);
          this.createMappin(latlng,this.mainMapPin)
          this.showLoader = false
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        this.axios.get(`/mapapi/t2000310/v1/shape_walk?start={"lat":${facilityLat},"lon":${facilityLng}}&goal={"lat":${selectLat},"lon":${selectLng}}&via=${viaLatLng}&via_type=optimal&format=geojson&request_code=gt56yhnbvfr4&signature=13558782f7b7a85a80431468ea42ff7ce14aae94f4b4445c71636794b5613750`)
        .then(response => {
          console.log(response)
          this.routeLine = window.navitime.geo.GeoJSON.draw({map: this.map, json: response.data});
          this.createSelectPoint(selectLat,selectLng,selectName)
          let latlng = new window.navitime.geo.LatLng(this.facility.lat,this.facility.lng);
          this.createMappin(latlng,this.mainMapPin)
          this.createViaPointPin()
          this.showLoader = false
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
  }
}


